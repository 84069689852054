<!--  -->
<template>
  <div class="c404">
    <div>
      <img src="../../assets/images/404.png" alt="" />
      <p>抱歉，页面出错啦~</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "notFound",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.c404 {
  // background-color: red;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }
  img {
    width: 296px;
    height: 145px;
    margin-bottom: 21px;
  }
}
</style>
